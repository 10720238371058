exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-jp-tsx": () => import("./../../../src/pages/about.jp.tsx" /* webpackChunkName: "component---src-pages-about-jp-tsx" */),
  "component---src-pages-about-ms-tsx": () => import("./../../../src/pages/about.ms.tsx" /* webpackChunkName: "component---src-pages-about-ms-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-credits-tsx": () => import("./../../../src/pages/credits.tsx" /* webpackChunkName: "component---src-pages-credits-tsx" */),
  "component---src-pages-hire-me-index-jp-tsx": () => import("./../../../src/pages/hire-me/index.jp.tsx" /* webpackChunkName: "component---src-pages-hire-me-index-jp-tsx" */),
  "component---src-pages-hire-me-index-ms-tsx": () => import("./../../../src/pages/hire-me/index.ms.tsx" /* webpackChunkName: "component---src-pages-hire-me-index-ms-tsx" */),
  "component---src-pages-hire-me-index-tsx": () => import("./../../../src/pages/hire-me/index.tsx" /* webpackChunkName: "component---src-pages-hire-me-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-projects-buku-kira-kira-index-tsx": () => import("./../../../src/pages/projects/buku-kira-kira/index.tsx" /* webpackChunkName: "component---src-pages-projects-buku-kira-kira-index-tsx" */),
  "component---src-pages-projects-fchat-index-tsx": () => import("./../../../src/pages/projects/fchat/index.tsx" /* webpackChunkName: "component---src-pages-projects-fchat-index-tsx" */),
  "component---src-pages-projects-game-impianku-index-tsx": () => import("./../../../src/pages/projects/game-impianku/index.tsx" /* webpackChunkName: "component---src-pages-projects-game-impianku-index-tsx" */),
  "component---src-pages-projects-hacking-blog-index-tsx": () => import("./../../../src/pages/projects/hacking-blog/index.tsx" /* webpackChunkName: "component---src-pages-projects-hacking-blog-index-tsx" */),
  "component---src-pages-projects-ilmu-adalah-kuasa-index-tsx": () => import("./../../../src/pages/projects/ilmu-adalah-kuasa/index.tsx" /* webpackChunkName: "component---src-pages-projects-ilmu-adalah-kuasa-index-tsx" */),
  "component---src-pages-projects-index-tsx": () => import("./../../../src/pages/projects/index.tsx" /* webpackChunkName: "component---src-pages-projects-index-tsx" */),
  "component---src-pages-projects-infografik-sifat-solat-nabi-index-tsx": () => import("./../../../src/pages/projects/infografik-sifat-solat-nabi/index.tsx" /* webpackChunkName: "component---src-pages-projects-infografik-sifat-solat-nabi-index-tsx" */),
  "component---src-pages-projects-isave-index-tsx": () => import("./../../../src/pages/projects/isave/index.tsx" /* webpackChunkName: "component---src-pages-projects-isave-index-tsx" */),
  "component---src-pages-projects-isave-popover-tsx": () => import("./../../../src/pages/projects/isave/Popover.tsx" /* webpackChunkName: "component---src-pages-projects-isave-popover-tsx" */),
  "component---src-pages-projects-kubus-index-tsx": () => import("./../../../src/pages/projects/kubus/index.tsx" /* webpackChunkName: "component---src-pages-projects-kubus-index-tsx" */),
  "component---src-pages-projects-masukwaktu-index-tsx": () => import("./../../../src/pages/projects/masukwaktu/index.tsx" /* webpackChunkName: "component---src-pages-projects-masukwaktu-index-tsx" */),
  "component---src-pages-projects-nasihat-index-tsx": () => import("./../../../src/pages/projects/nasihat/index.tsx" /* webpackChunkName: "component---src-pages-projects-nasihat-index-tsx" */),
  "component---src-pages-projects-solatapi-index-tsx": () => import("./../../../src/pages/projects/solatapi/index.tsx" /* webpackChunkName: "component---src-pages-projects-solatapi-index-tsx" */),
  "component---src-pages-projects-wordpress-site-index-tsx": () => import("./../../../src/pages/projects/wordpress-site/index.tsx" /* webpackChunkName: "component---src-pages-projects-wordpress-site-index-tsx" */),
  "component---src-pages-resume-tsx": () => import("./../../../src/pages/resume.tsx" /* webpackChunkName: "component---src-pages-resume-tsx" */),
  "component---src-templates-blog-list-template-tsx": () => import("./../../../src/templates/BlogListTemplate.tsx" /* webpackChunkName: "component---src-templates-blog-list-template-tsx" */),
  "component---src-templates-blog-template-tsx": () => import("./../../../src/templates/BlogTemplate.tsx" /* webpackChunkName: "component---src-templates-blog-template-tsx" */),
  "component---src-templates-note-list-template-tsx": () => import("./../../../src/templates/NoteListTemplate.tsx" /* webpackChunkName: "component---src-templates-note-list-template-tsx" */),
  "component---src-templates-note-template-tsx": () => import("./../../../src/templates/NoteTemplate.tsx" /* webpackChunkName: "component---src-templates-note-template-tsx" */)
}

